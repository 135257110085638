import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import LoginDialog from "./components/auth/LoginDialog";
import { AuthProvider } from "./components/auth/AuthProvider";
import PageTracker from "./components/utils/PageTracker.js";
import { Fade } from "@mui/material"; // Import Box from MUI

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(
		!!localStorage.getItem("user_email"),
	);
	const [loginOpen, setLoginOpen] = useState(!isAuthenticated);
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

	// Function to handle successful login
	const handleLoginSuccess = () => {
		setIsAuthenticated(true);
		setLoginOpen(false);
	};

	// Adjust viewport height for mobile devices
	useEffect(() => {
		const updateViewportHeight = () => {
			setViewportHeight(window.innerHeight);
		};

		window.addEventListener("resize", updateViewportHeight);

		// Initial call to set the viewport height
		updateViewportHeight();

		return () => window.removeEventListener("resize", updateViewportHeight);
	}, []);
	return (
		<AuthProvider>
			<Router>
				{/* Conditionally render routes based on authentication status */}
				{isAuthenticated ? (
					<>
						<AppRoutes /> {/* Render routes if authenticated */}
						<PageTracker isAuthenticated={isAuthenticated} />
						{/* Track page views if authenticated */}
					</>
				) : (
					<LoginDialog
						open={loginOpen}
						onClose={() => setLoginOpen(false)}
						onLoginSuccess={handleLoginSuccess}
						TransitionComponent={Fade} // Apply Fade transition to the dialog
						transitionDuration={1000} // Customize the duration of the fade
					/>
				)}
			</Router>
		</AuthProvider>
	);
}
export default App;
