import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../../supabase/supabaseClient";

/*
REMEMBER - DEPLOYING AN EDGE FUNCTION REQUIRES YOU TO USE 'NPX SUPABASE FUNCTIONS'

remaining steps: 
- figure out why the ip is returned with multiple fields
- figure out how to write to the supabase db from my edge function (use static data for the fields)
- pass in data to write to the db from client
- update emailer
*/

const PageTracker = ({ isAuthenticated }) => {
	const location = useLocation(); // Tracks the current page - hook
	const startTimeRef = useRef(Date.now()); // Ref to store the start time - ref is used to store mutable values that will NOT trigger a rerender when the value is updated
	const intervalRef = useRef(null); // Ref to store the interval ID - holds the reference to the setInterval ID, so it can be cleared when the component unmounts or the user navigates away
	const isLoggingRef = useRef(true); // Ref to track if logging is active or paused - logging is paused if the user navigates away
	const inactivityTimeoutRef = useRef(null); // Ref to store inactivity timeout ID
	const intervalValue = 20 * 1000; // write every 20s
	const hideTimeoutRef = useRef(null);

	const initialTime = 90000; // 1 minute 20 seconds of no interaction pauses logging
	let remainingTime = initialTime;
	const decrementInterval = 1000; // Decrement every 1 second

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (isAuthenticated) {
			const userEmail = localStorage.getItem("user_email");

			const logTimeSpent = async () => {
				if (userEmail && startTimeRef.current && isLoggingRef.current) {
					const currentTime = Date.now();
					const timeSpentSinceLastLog = currentTime - startTimeRef.current;
					startTimeRef.current = currentTime; // Reset start time for the next interval

					const now = new Date();
					const timestamp = now.toISOString();

					try {
						const functionUrl =
							process.env.REACT_APP_SUPABASE_FUNCTION_URL || "";
						const anonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || "";

						if (!functionUrl || !anonKey) {
							throw new Error("Supabase function URL or Anon Key not defined.");
						}

						const payload = {
							user_email: userEmail,
							page: location.pathname,
							time_spent: timeSpentSinceLastLog,
							timestamp: timestamp,
						};

						const response = await fetch(functionUrl, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${anonKey}`,
							},
							body: JSON.stringify(payload),
						});

						if (!response.ok) {
							const errorData = await response.json();
							throw new Error(errorData.Error || "Failed to send data.");
						}

						const responseData = await response.json();
						// console.log("API Response:", responseData);
					} catch (apiError) {
						console.error("error sending data:", apiError);
					}
				}
			};

			intervalRef.current = setInterval(logTimeSpent, intervalValue); // Run every 20 seconds (1 minute)

			const stopLoggingAndClearInterval = async () => {
				isLoggingRef.current = false;

				if (intervalRef.current) {
					clearInterval(intervalRef.current); // Clear the interval when the component unmounts
				}

				// Final logging of remaining time before navigation - runs right before the user switches routes
				const now = new Date();
				const finalTimeSpent = now - startTimeRef.current;
				const timestamp = now.toISOString();

				// write to db
				try {
					const functionUrl = process.env.REACT_APP_SUPABASE_FUNCTION_URL || "";
					const anonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || "";

					if (!functionUrl || !anonKey) {
						throw new Error("Supabase function URL or Anon Key not defined.");
					}

					const payload = {
						user_email: userEmail,
						page: location.pathname,
						time_spent: finalTimeSpent,
						timestamp: timestamp,
					};

					const response = await fetch(functionUrl, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${anonKey}`,
						},
						body: JSON.stringify(payload),
					});

					if (!response.ok) {
						const errorData = await response.json();
						throw new Error(errorData.Error || "Failed to send data.");
					}

					const responseData = await response.json();
					// console.log("API Response:", responseData);
				} catch (apiError) {
					console.error("error sending data:", apiError);
				}
			};

			// Function to reset inactivity timer
			const resetInactivityTimer = () => {
				// Clear any existing timeout
				if (inactivityTimeoutRef.current) {
					clearInterval(inactivityTimeoutRef.current);
				}

				if (!isLoggingRef.current) {
					// user returned from idle state
					startTimeRef.current = Date.now();
					isLoggingRef.current = true;
				}

				remainingTime = initialTime;

				inactivityTimeoutRef.current = setInterval(() => {
					remainingTime -= decrementInterval;

					// When remaining time reaches 0, stop logging and clear the interval
					if (remainingTime <= 0) {
						clearInterval(inactivityTimeoutRef.current);
						inactivityTimeoutRef.current = null;
						isLoggingRef.current = false;
					}
				}, decrementInterval);
			};

			// Handle mouse, scroll, and touch events
			const handleUserActivity = () => {
				resetInactivityTimer();
			};

			// Attach event listeners for mouse, scroll, and touch activity
			document.addEventListener("mousemove", handleUserActivity);
			document.addEventListener("touchstart", handleUserActivity, {
				passive: true,
			}); // Mobile touch events

			// Handle Visibility Changes (if the user switches to a new browser tab)
			const handleVisibilityChange = async () => {
				const currentTime = Date.now();

				if (document.visibilityState === "hidden") {
					isLoggingRef.current = false;

					const timeSpentBeforeHidden = currentTime - startTimeRef.current;

					hideTimeoutRef.current = setTimeout(async () => {
						const now = new Date();
						const timestamp = now.toISOString();

						const { error } = await supabase.from("page_views").insert([
							{
								user_email: userEmail,
								page: location.pathname,
								time_spent: timeSpentBeforeHidden,
								timestamp: timestamp,
							},
						]);

						// if (error) {
						//   console.log("error logging time on tab hide:", error);
						// }
					}, 1000);
				} else if (document.visibilityState === "visible") {
					// Resume logging when the page becomes visible again
					isLoggingRef.current = true;

					if (hideTimeoutRef.current) {
						clearTimeout(hideTimeoutRef.current); // Cancel the timeout if the user returns quickly
					}

					if (intervalRef.current) {
						clearInterval(intervalRef.current);
					}

					intervalRef.current = setInterval(logTimeSpent, intervalValue);

					startTimeRef.current = Date.now(); // Reset the start time (might be wrong)
				}
			};

			document.addEventListener("visibilitychange", handleVisibilityChange);

			// Cleanup function: clear the interval and log the remaining time
			return async () => {
				await stopLoggingAndClearInterval();
				// console.log("cleanup event listeners");
				// Cleanup the event listener
				document.removeEventListener(
					"visibilitychange",
					handleVisibilityChange,
				);
				document.removeEventListener("mousemove", handleUserActivity);
				document.removeEventListener("touchstart", handleUserActivity);
			};
		}
	}, [isAuthenticated, location]);

	return null;
};

export default PageTracker;
